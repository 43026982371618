<template>
  <div class="mb-[121px] lg:mb-0">
    <div v-for="(issue, index) in issues" :key="index" class="mt-24 first:mt-0">
      <h2 class="heading-2">{{ issue.label }}</h2>

      <div class="mt-16">
        <IssuesCheckboxes
          v-model="checkedIssues[index]"
          :customer-issue="issue.customerIssue"
          :siblings="issue.siblings"
        />
      </div>
    </div>

    <div class="mt-32 hidden md:block">
      <RevButton
        :disabled="!hasCheckedIssues"
        full-width="always"
        variant="primary"
        @click="onContinue"
      >
        {{ i18n(translations.continue) }}
      </RevButton>
    </div>

    <RevStickyBar class="md:hidden" position="bottom">
      <RevButton
        :disabled="!hasCheckedIssues"
        full-width="always"
        variant="primary"
        @click="onContinue"
      >
        {{ i18n(translations.continue) }}
      </RevButton>
    </RevStickyBar>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { type IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevStickyBar } from '@ds/components/StickyBar'

import IssuesCheckboxes from '../IssuesCheckboxes/IssuesCheckboxes.vue'

import translations from './IssuesLayout.translations'
import type { IssuesLayoutProps } from './IssuesLayout.types'

const emit = defineEmits<{
  continue: [value: IssueSibling[]]
}>()

const props = defineProps<IssuesLayoutProps>()

const i18n = useI18n()

const checkedIssues = ref<string[][]>(Array.from(props.issues, () => []))

const hasCheckedIssues = computed(() => {
  if (isEmpty(checkedIssues.value)) {
    return false
  }

  return checkedIssues.value.every((checkedIssue) => checkedIssue.length >= 1)
})

function onContinue() {
  const formatIssuesChecked = props.issues
    .map((issue, index) =>
      issue.siblings.filter((sibling) => {
        if (!isEmpty(checkedIssues.value[index])) {
          return checkedIssues.value[index].some((checkedIssue) =>
            sibling.customerIssue.includes(checkedIssue),
          )
        }

        return []
      }),
    )
    .flat()

  emit('continue', formatIssuesChecked)
}
</script>
