<template>
  <main class="contents">
    <FailLoadingScreen
      v-if="displayFailLoadingScreen"
      data-qa="diagnosis-fail-loading-screen"
      data-test="diagnosis-fail-loading-screen"
      @retry="onRetry"
    />

    <InvalidProblemErrorScreen
      v-if="displayInvalidLoadingProblemContextErrorScreen"
    />

    <LoadingScreen
      v-if="displayLoadingScreen"
      data-qa="diagnosis-loading-screen"
      data-test="diagnosis-loading-screen"
      :loading-text="i18n(translations.loadingText)"
    />

    <PickIssuesScreen
      v-if="displayPickIssuesScreen && orderline"
      data-qa="diagnosis-pick-issues-screen"
      data-test="diagnosis-pick-issues-screen"
      :machine-context="state.context"
      :machine-send="send"
      :order-id="orderline.orderId"
      :product-image="orderline.product.image"
      :product-title="orderline.product.title"
      @cancel="onCancel"
    />

    <CancellationModal />
    <ImeiModal
      :imei="imei"
      @close="onClosedModal"
      @still-need-help="onIMEIstillNeedHelp"
    />

    <ImeiUnavailableModal
      :first-name="user.firstName"
      @close="onClosedModal"
      @still-need-help="onIMEIstillNeedHelp"
    />

    <InsurerPortalModal
      :insurer-portal-url="insurerPortalUrl"
      @close="onClosedModal"
    />

    <InvoiceRequestCanceledModal @close="onClosedModal" />

    <InvoiceRequestModal @close="onClosedModal" />

    <InvoiceRequestPendingModal
      @close="onClosedModal"
      @send-another-invoice-request="onSendAnotherInvoiceRequest"
    />

    <MobileServiceRedirectModal
      v-if="
        orderline?.bundledMobilePlan &&
        state.context.mobileServicePartnerProviderLabel &&
        state.context.mobileServicePartnerProviderUrl
      "
      :mobile-service-name="state.context.mobileServicePartnerProviderLabel"
      :orderline-id="orderline.orderlineId"
      :redirection-url="state.context.mobileServicePartnerProviderUrl"
      @close="onClosedModal"
    />

    <PreShippingInvoiceRequestModal @close="onClosedModal" />

    <RequestFailedToast v-if="openRequestFailedToast" @closed="onClosedToast" />
  </main>
</template>

<script lang="ts" setup>
import { createError, useRoute } from '#imports'
import { computed, onMounted, onUnmounted, shallowRef } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useUserStore } from '@backmarket/nuxt-module-oauth/useUserStore'
import { openModal } from '@ds/components/ModalBase'
import {
  type ActorRef,
  type State,
  type StateSchema,
  type Typestate,
  interpret,
} from 'xstate'

import { useOrderlineDetails } from '@/scopes/care-commons/components/order/OrderlineCard/composables/useOrderlineDetails'
import MobileServiceRedirectModal from '@/scopes/care-commons/modals/MobileServiceRedirectModal/MobileServiceRedirectModal.vue'

import FailLoadingScreen from '../common/components/FailLoadingScreen/FailLoadingScreen.vue'
import InvalidProblemErrorScreen from '../common/components/InvalidProblemErrorScreen/InvalidProblemErrorScreen.vue'
import LoadingScreen from '../common/components/LoadingScreen/LoadingScreen.vue'
import type {
  ToastMachineContext,
  ToastMachineEvent,
} from '../common/machine/Toast.machine.types'
import CancellationModal from '../common/modals/CancellationModal/CancellationModal.vue'
import { CUSTOMER_CARE_MODALS_NAMES } from '../common/modals/names'

import translations from './Diagnosis.translations'
import PickIssuesScreen from './components/PickIssuesScreen/PickIssuesScreen.vue'
import RequestFailedToast from './components/RequestFailedToast/RequestFailedToast.vue'
import { createDiagnosisMachine } from './machine/Diagnosis.machine'
import { actors, toasts } from './machine/Diagnosis.machine.constants'
import { getExternalUrlFromSelectedIssues } from './machine/selectors/getExternalUrl'
import { getIMEI } from './machine/selectors/getIMEI'
import ImeiModal from './modals/ImeiModal/ImeiModal.vue'
import ImeiUnavailableModal from './modals/ImeiUnavailableModal/ImeiUnavailableModal.vue'
import InsurerPortalModal from './modals/InsurerPortalModal/InsurerPortalModal.vue'
import InvoiceRequestCanceledModal from './modals/InvoiceRequestCanceledModal/InvoiceRequestCanceledModal.vue'
import InvoiceRequestModal from './modals/InvoiceRequestModal/InvoiceRequestModal.vue'
import InvoiceRequestPendingModal from './modals/InvoiceRequestPendingModal/InvoiceRequestPendingModal.vue'
import PreShippingInvoiceRequestModal from './modals/PreShippingInvoiceRequestModal/PreShippingInvoiceRequestModal.vue'

const i18n = useI18n()
const { params } = useRoute()
const { user } = useUserStore()

const {
  execute,
  data: orderline,
  error,
} = await useOrderlineDetails({
  orderlineId: params.orderlineId as string,
})

await execute()

if (error.value) {
  throw createError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.message,
    fatal: true,
  })
}

const diagnosisMachine = createDiagnosisMachine('LOADING')

const state = shallowRef(diagnosisMachine.initialState)

const service = interpret(
  diagnosisMachine.withContext({
    ...diagnosisMachine.context,
    orderId: orderline.value?.orderId ?? null,
    orderlineId: orderline.value?.orderlineId ?? null,
    mobileServicePartnerProviderLabel:
      orderline.value?.bundledMobilePlan?.partnerProviderLabel ?? null,
    mobileServicePartnerProviderUrl:
      orderline.value?.bundledMobilePlan?.partnerPortalUrl ?? null,
  }),
  {
    devTools: false,
  },
).onTransition((newState) => {
  state.value = newState
})
const { send } = service
onMounted(() => {
  service.start()
})
onUnmounted(() => {
  service.stop()
})

const displayFailLoadingScreen = computed(() =>
  state.value.matches('LOADING_FAILED'),
)

const displayLoadingScreen = computed(() => state.value.hasTag('loading'))

const displayInvalidLoadingProblemContextErrorScreen = computed(() =>
  state.value.matches({
    GET_ACTION_TO_PERFORM: 'LOADING_PROBLEM_CONTEXT_FAILED',
  }),
)

const imei = computed(() => getIMEI(state.value.context.issueAction))

const displayPickIssuesScreen = computed(() =>
  state.value.matches('ISSUE_SELECTION'),
)

const insurerPortalUrl = computed(() =>
  getExternalUrlFromSelectedIssues(state.value.context.pageData),
)

const toastSnapshot = computed<State<
  ToastMachineContext,
  ToastMachineEvent,
  StateSchema<ToastMachineContext>,
  Typestate<ToastMachineContext>
> | null>(() => {
  const toastActor = state.value.children[actors.Toast]

  if (toastActor) {
    const toastActorRef = toastActor as ActorRef<ToastMachineEvent>

    return toastActorRef.getSnapshot()
  }

  return null
})

const openRequestFailedToast = computed(
  () =>
    toastSnapshot.value?.context.activeToast === toasts.RequestFailed &&
    toastSnapshot.value?.matches('open'),
)

function onCancel() {
  openModal(CUSTOMER_CARE_MODALS_NAMES.cancellationModal)
}

function onClosedModal() {
  send('MODAL_CLOSED')
}

function onClosedToast() {
  send('TOAST_CLOSED')
}

function onIMEIstillNeedHelp() {
  send('CONTINUE_WITH_SELECTED_ISSUES')
}

function onRetry() {
  send('RETRY')
}

function onSendAnotherInvoiceRequest() {
  send('SEND_ANOTHER_INVOICE_REQUEST')
}
</script>
