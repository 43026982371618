<template>
  <div
    class="grid w-full grow grid-cols-1 max-md:grid-flow-row md:grid-flow-col md:grid-cols-12"
    :class="
      !withMobileWebSidebar
        ? 'max-md:grid-rows-1'
        : 'max-md:grid-rows-[auto_1fr]'
    "
  >
    <div
      class="bg-surface-default-hi md:col-span-5"
      :class="!withMobileWebSidebar ? 'max-md:hidden' : ''"
    >
      <aside
        class="flex flex-1 px-24 pb-24 pt-8 md:justify-center md:pt-32 lg:justify-end lg:pl-0 lg:pr-96"
      >
        <div class="w-full md:max-w-380">
          <div class="flex flex-1 items-center justify-between py-4">
            <RevLink
              class="body-2 flex items-center space-x-4"
              :underlined="false"
              @click="back"
            >
              <IconArrowLeft />
              <span>{{ backLabel }}</span>
            </RevLink>

            <RevButtonRounded
              :aria-label="i18n(translations.cancel)"
              class="block md:hidden"
              data-qa="cancel-request-icon"
              :icon="IconCross"
              variant="secondary"
              @click="cancel"
            />
          </div>

          <div v-if="$slots.sidebar" class="pt-16">
            <slot name="sidebar" />
          </div>
        </div>
      </aside>
    </div>

    <div class="bg-surface-default-mid md:col-span-7">
      <main
        class="flex flex-1 px-24 pb-24 md:justify-center lg:justify-start lg:pl-0 lg:pr-0"
      >
        <div class="w-full md:max-w-640 lg:mx-auto">
          <div
            class="hidden flex-1 items-center justify-end py-4 pt-32"
            :class="!withMobileWebSidebar ? '' : 'md:flex'"
          >
            <RevButtonRounded
              :aria-label="i18n(translations.cancel)"
              class="hidden md:block"
              data-qa="cancel-request-icon"
              :icon="IconCross"
              variant="secondary"
              @click="cancel"
            />
          </div>

          <div v-if="$slots.content" class="pt-24 md:pt-16">
            <slot name="content" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevLink } from '@ds/components/Link'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconCross } from '@ds/icons/IconCross'

import translations from './MinimalWithSideBarLayout.translations'
import type { MinimalWithSideBarLayoutProps } from './MinimalWithSideBarLayout.types'

const props = withDefaults(defineProps<MinimalWithSideBarLayoutProps>(), {
  withMobileWebSidebar: true,
})

const emits = defineEmits(['back', 'cancel'])

const i18n = useI18n()

const backLabel = props.ctaBackLabel ?? i18n(translations.back)

function back() {
  emits('back')
}

function cancel() {
  emits('cancel')
}
</script>
