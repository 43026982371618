<template>
  <div class="space-y-16">
    <RevCheckbox
      v-for="sibling in siblings"
      :id="setCheckboxId(customerIssue, sibling.customerIssue)"
      :key="setCheckboxId(customerIssue, sibling.customerIssue)"
      v-model="checkedIssues"
      :data-qa="sibling.customerIssue"
      :value="sibling.customerIssue"
      variant="full"
      @update:model-value="modelValueUpdated"
    >
      <template #label>
        {{ sibling.label }}
      </template>
    </RevCheckbox>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevCheckbox } from '@ds/components/Checkbox'

import type { IssuesCheckboxesProps } from './IssuesCheckboxes.types'

const emit = defineEmits<{
  'update:modelValue': [string[] | boolean]
}>()

defineProps<IssuesCheckboxesProps>()

const checkedIssues = ref<string[]>([])

function modelValueUpdated(value: string[] | boolean) {
  if (typeof value === 'boolean') {
    return
  }

  emit('update:modelValue', value)
}

function setCheckboxId(
  customerIssue: string | undefined,
  siblingCustomerIssue: string,
) {
  if (!isEmpty(customerIssue)) {
    return `${customerIssue}-with-sibling-${siblingCustomerIssue}-checkbox`
  }

  return `${siblingCustomerIssue}-checkbox`
}
</script>
