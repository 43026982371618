<template>
  <RevCard class="flex items-center space-x-16 px-24 py-20">
    <RevIllustration alt="" :height="40" :src="productImage" :width="40" />

    <div class="flex flex-1 flex-col text-left">
      <p class="body-2-bold">
        {{ productTitle }}
      </p>

      <p class="caption">
        {{ i18n(translations.orderNumber, { orderId }) }}
      </p>
    </div>
  </RevCard>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './OrderlineRecapBlock.translations'
import type { OrderlineRecapBlockProps } from './OrderlineRecapBlock.types'

defineProps<OrderlineRecapBlockProps>()

const i18n = useI18n()
</script>
