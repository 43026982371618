<template>
  <div>
    <div v-if="!isMultiSelectIssues">
      <h2 class="heading-2">{{ issuesListHeading }}</h2>

      <IssuesList
        class="mb-32"
        :siblings="issue.siblings"
        @continue="onContinueWithSingleIssueSelection"
      />
    </div>

    <div v-if="isMultiSelectIssues">
      <h2 class="heading-2">
        {{ i18n(translations.issuesCheckboxesHeading) }}
      </h2>

      <p class="body-1 mt-1">
        {{ i18n(translations.issuesCheckboxesInformativeText) }}
      </p>

      <div class="mb-[121px] mt-16 lg:mb-32">
        <IssuesCheckboxes v-model="checkedIssues" :siblings="issue.siblings" />
      </div>

      <div class="hidden md:block">
        <RevButton
          :disabled="!hasCheckedIssues"
          full-width="always"
          variant="primary"
          @click="onContinueWithMultipleIssueSelection"
        >
          {{ i18n(translations.continue) }}
        </RevButton>
      </div>

      <RevStickyBar class="md:hidden" position="bottom">
        <RevButton
          :disabled="!hasCheckedIssues"
          full-width="always"
          variant="primary"
          @click="onContinueWithMultipleIssueSelection"
        >
          {{ i18n(translations.continue) }}
        </RevButton>
      </RevStickyBar>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import {
  CUSTOMER_ISSUE,
  type IssueSibling,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevStickyBar } from '@ds/components/StickyBar'

import IssuesCheckboxes from '../IssuesCheckboxes/IssuesCheckboxes.vue'
import IssuesList from '../IssuesList/IssuesList.vue'

import translations from './IssueLayout.translations'
import type { IssueLayoutProps } from './IssueLayout.types'

const emit = defineEmits<{
  continue: [value: IssueSibling | IssueSibling[]]
}>()

const props = defineProps<IssueLayoutProps>()

const i18n = useI18n()

const checkedIssues = ref<string[]>([])

const isMultiSelectIssues = computed(() => props.issue.isMultiSelect)

const hasCheckedIssues = computed(() => !isEmpty(checkedIssues.value))

const issuesListHeading = computed(() => {
  if (isMultiSelectIssues.value) {
    return null
  }

  if (props.issue.customerIssue === CUSTOMER_ISSUE.root) {
    return i18n(translations.rootIssueListHeading)
  }

  return i18n(translations.issueListHeading)
})

function onContinueWithMultipleIssueSelection() {
  const formatIssuesChecked = props.issue.siblings.filter((sibling) =>
    checkedIssues.value.some((checkedIssue) =>
      sibling.customerIssue.includes(checkedIssue),
    ),
  )

  emit(
    'continue',
    formatIssuesChecked.length > 1
      ? formatIssuesChecked
      : formatIssuesChecked[0],
  )
}

function onContinueWithSingleIssueSelection(event: IssueSibling) {
  emit('continue', event)
}
</script>
