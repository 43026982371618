<template>
  <RevModal
    closable
    :name="DIAGNOSIS_MODALS_NAMES.imeiModal"
    :title="i18n(translations.yourNumber)"
    variant="confirmation"
  >
    <template #body>
      <div>
        <CopyToClipboard
          :copy-button-alt-text="i18n(translations.copyButton)"
          :copy-value="imei"
          :toast-close-alt-text="i18n(translations.closeAltText)"
          :toast-title="i18n(translations.copySuccessTitle)"
        />
      </div>

      <p
        class="body-2 mt-24 text-center"
        data-qa="find-on-keypad"
        data-test="find-on-keypad"
      >
        <FormattedMessage :definition="translations.findOnKeypad">
          <template #imei>
            <span class="body-2-bold">*#06#</span>
          </template>
        </FormattedMessage>
      </p>

      <p class="body-2-bold mt-12 text-center underline">
        <RevLink @click="emit('still-need-help')">
          {{ i18n(translations.stillNeedHelpLink) }}
        </RevLink>
      </p>
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { RevModal } from '@ds/components/Modal'

import CopyToClipboard from '../../components/CopyToClipboard/CopyToClipboard.vue'
import { DIAGNOSIS_MODALS_NAMES } from '../name'

import translations from './ImeiModal.translations'
import type { ImeiModalProps } from './ImeiModal.types'

const i18n = useI18n()

withDefaults(defineProps<ImeiModalProps>(), {
  imei: '',
})

const emit = defineEmits(['still-need-help'])
</script>
