import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

export interface IssuesCommunication {
  selectedIssues: string[]
  tags: string[]
}

export function encodeIssues(issues: IssuesCommunication) {
  const logger = useLogger()

  try {
    const json = JSON.stringify(issues)
    const base64 = btoa(json)

    return base64
  } catch (error) {
    logger.error('[Diagnosis] Error occured when encoding issues', {
      error: error as Error,
      issues,
    })
    throw error
  }
}

export function decodeIssues(issuesBase64String: string) {
  const logger = useLogger()

  try {
    const rawString = atob(issuesBase64String)
    const json: IssuesCommunication = JSON.parse(rawString)

    return json
  } catch (error) {
    logger.error('[Diagnosis] Error occured when decoding issues', {
      error: error as Error,
      issues: issuesBase64String,
    })
    throw error
  }
}
