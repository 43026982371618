import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import type { ErrorPlatformEvent } from 'xstate'

export const logServiceError = (label: string, event: ErrorPlatformEvent) => {
  const logger = useLogger()

  logger.error(label, {
    error: event.data,
  })
}
