<template>
  <RevModal
    closable
    :name="DIAGNOSIS_MODALS_NAMES.invoiceRequestModal"
    :title="i18n(translations.title)"
    variant="confirmation"
  >
    <template #body>
      <p class="body-1 mb-12 mt-24 md:mb-32">
        {{ i18n(translations.content) }}
      </p>
    </template>

    <template #footer>
      <RevButton
        class="mb-12"
        data-qa="goto-my-order"
        full-width="always"
        :to="myOrdersLink"
        variant="primary"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'
import { RevModal } from '@ds/components/Modal'

import { DASHBOARD } from '@/scopes/dashboard/routes'

import { DIAGNOSIS_MODALS_NAMES } from '../name'

import translations from './InvoiceRequestModal.translations'

const i18n = useI18n()
const injectLocale = useRouteLocationWithLocale()

const myOrdersLink = toNuxtLink(
  injectLocale({
    type: 'internal',
    name: DASHBOARD.MY_ORDERS.SELF,
  }),
)
</script>
