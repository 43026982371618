import type { IssueAction, IssueTag } from './common'

export const CUSTOMER_ISSUE = {
  root: 'ROOT',
} as const

export type CustomerIssue =
  | string
  | (typeof CUSTOMER_ISSUE)[keyof typeof CUSTOMER_ISSUE]

export type IssueSibling = {
  customerIssue: CustomerIssue
  label: string
  icon: string | null
  tag: IssueTag | null
  isMultiSelect: boolean
  addOnNavigate: boolean
  action: IssueAction | null
}

export type Issue = {
  customerIssue: CustomerIssue
  label: string
  icon: string | null
  tag: IssueTag | null
  isMultiSelect: boolean
  addOnNavigate: boolean
  action: IssueAction | null
  siblings: IssueSibling[]
}

export type IssueListResponse = Issue[]
