import {
  type GetActionQueryParams,
  getProblem,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { MachineContext } from '../Diagnosis.machine.types'
import { getAllSelectedCustomerIssuesWithAction } from '../selectors/getAllSelectedIssues'

export const getAction = async ({
  orderlineId,
  pageData,
}: {
  orderlineId: MachineContext['orderlineId']
  pageData: MachineContext['pageData']
}) => {
  const allSelectedCustomerIssuesWithAction =
    getAllSelectedCustomerIssuesWithAction(pageData)

  const params: GetActionQueryParams = {
    customerIssues:
      allSelectedCustomerIssuesWithAction.selectedIssues.join(';'),

    orderlineId: orderlineId as number,
  }

  const payload = await $httpFetch(getProblem, {
    queryParams: {
      ...params,
    },
  })

  if (!isEmpty(payload)) {
    return payload.action
  }

  throw new Error('No action found')
}
